import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { Layout, PageSection, SEO } from "../components"

const NotFoundPage = () => (
  <Layout>
    <>
      <SEO title="404: Not found" />

      <PageSection id="404-content">
        <h1>
          Oops&hellip;not found <span aria-label="Frowning face">😟</span>
        </h1>
        <p>You’ve found a page that doesn’t exist anymore&hellip;sorry!</p>

        <p>
          Here’s my{" "}
          <OutboundLink
            href="https://open.spotify.com/playlist/4AxeXo4yDwhmhdFKYslGve?si=ipl34X0WQb--VthWaEb-Bg"
            rel="external noopener nofollow"
            target="_blank"
          >
            Running Playlist
          </OutboundLink>{" "}
          to keep you occupied.
        </p>

        <div className="embed-container">
          <iframe
            title="Spotify playlist"
            className="iframe--spotify "
            src="https://open.spotify.com/embed/playlist/4AxeXo4yDwhmhdFKYslGve?si=ipl34X0WQb--VthWaEb-Bg"
          />
        </div>
      </PageSection>
    </>
  </Layout>
)

export default NotFoundPage
